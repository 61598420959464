class SubMainNavMobile {
    constructor(el) {
        this.el = el
        this.toggleEl = this.el.querySelector('.js-main-nav-mobile__submenu-toggle')
        this.containerEl = this.el.querySelector('.js-main-nav-mobile__submenu-container')
        this.innerEl = this.el.querySelector('.js-main-nav-mobile__submenu-inner')

        this.toggleEl.addEventListener('click', (e) => {
            e.preventDefault();
            // this.toggleOpen()
            this.containerEl.classList.toggle('menuIsOpen');
        })
    }

    // toggleOpen() {
    //     if (this.el.classList.toggle('is-open')) {
    //         const height = this.innerEl.offsetHeight
    //         // this.containerEl.style.height = height + 'px'
    //         console.log('click')
    //         // this.containerEl.style.display = 'block';
    //         this.containerEl.classList.toggle('menuIsOpen');

    //     } else {
    //         this.containerEl.style.Height = null;
    //     }

    // }
}

class MainNavMobile {
    constructor() {
        this.el = document.querySelector('.js-main-nav-mobile');
        this.subMenus = this.el.querySelectorAll('.js-main-nav-mobile__submenu');



        this.subMenus.forEach((subMenu) => {
            new SubMainNavMobile(subMenu)
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const mainNav = new MainNavMobile();
});
