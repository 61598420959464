class Accordion {
    constructor(element) {
        this.element = element;
        this.itemEls = element.querySelectorAll('.js-accordion__item')
        this.items = []

        Array.from(this.itemEls).forEach((itemEl) => {
            const newItem = {
                el: itemEl,
                isActive: false,
                header: itemEl.querySelector('.js-accordion__header'),
                main: itemEl.querySelector('.js-accordion__main'),
                mainInner: itemEl.querySelector('.js-accordion__main-inner'),
                button: itemEl.querySelector('.js-accordion__button'),
            }

            newItem.header.addEventListener('click', (e) => {
                this.toggle(e, newItem)
            })
            newItem.button.addEventListener('click', (e) => {
                this.toggle(e, newItem)
            })

            this.items.push(newItem)
        })

    }

    toggle(event, item) {
        event.preventDefault()
        item.el.classList.toggle('is-active')
        if (!item.isActive) {
            const height = item.mainInner.offsetHeight
            item.main.style.maxHeight = height + 'px'
        } else {
            item.main.style.removeProperty('max-height')
        }

        item.isActive = !item.isActive
    }

}

document.addEventListener('DOMContentLoaded', () => {
    const accordions = Array.from(document.querySelectorAll('.js-accordion'));
    if (accordions) {
        accordions.forEach(accordion => {
            new Accordion(accordion);
        })
    }
});