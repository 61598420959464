class Header {
    constructor() {
        this.el = document.querySelector('.js-header')
        this.megamenu = this.el.querySelector('.js-header__megamenu')
        this.megamenuInner = this.el.querySelector('.js-header__megamenu-inner')
        this.backdrop = this.el.querySelector('.js-header__backdrop')

        this.togglers = this.el.querySelectorAll('.js-header__toggler')
        this.menus = this.el.querySelectorAll('.js-header__menu')

        this.activeMenu = false

        this.togglers.forEach((menuToggler) => {
            menuToggler.addEventListener('click', (e) => {
                e.preventDefault()
                this.changeMenu(menuToggler.dataset.menu)
            })
        })

        this.backdrop.addEventListener('click', () => {
            this.close()
            this.backdrop.classList.remove('is-visible')
        })
    }

    changeMenu(menuId) {
        if (menuId === this.activeMenu) {
            //close
            this.close()
            this.backdrop.classList.remove('is-visible')
        } else if (this.activeMenu !== false) {
            //change
            this.close()
            setTimeout(() => {
                this.open(menuId)
            }, 300)
        } else {
            // open
            this.open(menuId)
            this.backdrop.classList.add('is-visible')
        }
    }

    open(menuId) {
        const toggler = Array.from(this.togglers).find(toggler => toggler.dataset.menu === menuId)
        const menu = Array.from(this.menus).find(menu => menu.dataset.menu === menuId)

        toggler.classList.add('is-active')
        menu.classList.add('is-active')
        this.toggleMegamenu()

        this.activeMenu = menuId
    }

    close() {
        const toggler = Array.from(this.togglers).find(toggler => toggler.dataset.menu === this.activeMenu)
        const menu = Array.from(this.menus).find(menu => menu.dataset.menu === this.activeMenu)

        toggler.classList.remove('is-active')
        setTimeout(() => {
            menu.classList.remove('is-active')
        }, 300)
        this.toggleMegamenu()

        this.activeMenu = false
    }
    toggleMegamenu() {
        if (this.megamenu.classList.toggle('is-open')) {
            const height = this.megamenuInner.offsetHeight
            this.megamenu.style.maxHeight = height + 'px'
        } else {
            this.megamenu.style.maxHeight = null
        }
    }

}

document.addEventListener('DOMContentLoaded', () => {
    new Header();

    document.addEventListener("scroll", handleScroll);
    // get a reference to our predefined button
    const scrollToTopBtn = document.getElementById("scrollToTopBtn");

    function handleScroll() {
        var scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        var GOLDEN_RATIO = 0.01;
        if (scrollToTopBtn) {
            if ((document.documentElement.scrollTop / scrollableHeight) > GOLDEN_RATIO) {
                //show button
                scrollToTopBtn.style.display = "block";
            } else {
                //hide button
                scrollToTopBtn.style.display = "none";
            }
        }
    }
    if (scrollToTopBtn) {
        scrollToTopBtn.addEventListener("click", scrollToTop);

        function scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }
    // if(window.location.href.includes('/en/')) {
    //     document.documentElement.setAttribute("lang", 'en-US');
    // }
    // else {
    //     document.documentElement.setAttribute("lang", 'pl-PL');
    // }
}); 