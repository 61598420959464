class SearchBar {
    constructor(element) {
        this.element = element;
        this.input = this.element.querySelector('.js-search-bar__input')
        this.submit = this.element.querySelector('.js-search-bar__submit')

        this.element.addEventListener('submit', (e) => {
            if (!this.element.classList.contains('is-open')) {
                this.element.classList.add('is-open')
                this.input.focus()
                e.preventDefault()
            } else if(this.input.value === '') {
                e.preventDefault()
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const searchBars = Array.from(document.querySelectorAll('.js-search-bar'));
    if (searchBars) {
        searchBars.forEach(searchBar => {
            new SearchBar(searchBar);
        })
    }
});