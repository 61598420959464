import Map from 'ol/Map';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import * as olInteraction from 'ol/interaction';
import * as olProj from 'ol/proj'
import { fromLonLat } from "ol/proj";

class DepartmentsMap {
    constructor(element) {
        this.center = [17.926048242134303, 50.668971817151416];
        this.mapEl = element.querySelector('.js-departments-map__map');
        this.markersData = JSON.parse(element.querySelector('.js-departments-map__data').textContent);
        this.markers = [];
        this.popup = null;
        this.popupContent = null;

        this.initMap()
        this.initMarkers()
        this.initPopup()
    }

    initMap() {
        this.map = new Map({
            target: this.mapEl,
            layers: [
                new TileLayer({
                    source: new OSM()
                })
            ],
            interactions: olInteraction.defaults({ mouseWheelZoom: false }),
            view: new View({
                center: olProj.fromLonLat(this.center),
                zoom: 15
            })
        });

        this.map.on('click', (e) => {
            this.hidePopup()
        })
    }

    initMarkers() {
        this.markersData.forEach((markerData) => {
            const icon = this.elFromString(markerData.icon)
            icon.classList.add('o-departments-map__marker')

            const marker = new Overlay({
                position: fromLonLat([markerData.lon, markerData.lat]),
                positioning: 'center-center',
                element: icon,
            });

            icon.addEventListener('click', () => {
                this.updatePopup(markerData)
            })

            this.markers.push(marker);
            this.map.addOverlay(marker);
        })
    }

    initPopup() {
        this.popupContent = this.elFromString('<div class="o-departments-map__popup is-hidden"></div>');

        this.popup = new Overlay({
            position: fromLonLat(this.center),
            positioning: 'bottom-left',
            element: this.popupContent,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
            className: 'o-departments-map__popup-wrapper',
        })

        this.map.addOverlay(this.popup);
    }

    updatePopup(markerData) {
        this.popupContent.innerHTML = '' +
            markerData.icon +
            '<a href="' + markerData.url + '">' + markerData.title + '</a>' +
            '<p>' + markerData.contact + '</p>';

        this.popup.setPosition(fromLonLat([markerData.lon, markerData.lat]))
        this.showPopup()
    }

    showPopup() {
        this.popupContent.classList.remove('is-hidden')
    }

    hidePopup() {
        this.popupContent.classList.add('is-hidden')
        setTimeout(() => {
            this.popup.setPosition(null)
        }, 300)
    }

    elFromString(html) {
        let el = document.createElement('template')
        el.innerHTML = html.trim()
        el = el.content.firstChild
        return el
    }
}


document.addEventListener('DOMContentLoaded', () => {
    const departmentsMaps = Array.from(document.querySelectorAll('.js-departments-map'));
    if (departmentsMaps) {
        departmentsMaps.forEach(departmentsMap => {
            new DepartmentsMap(departmentsMap);
        })
    }
});