import Cookies from 'js-cookie'

class HighContrast {
    constructor(element) {
        this.element = element;
        this.body = document.querySelector('body')

        this.changeMode(Cookies.get('high-contrast-mode') !== undefined)

        this.element.addEventListener('click', (e) => {
            e.preventDefault()
            this.changeMode(Cookies.get('high-contrast-mode') === undefined)
        })
    }

    changeMode(enable) {
        if (enable) {
            Cookies.set('high-contrast-mode', 'on', { expires: 30 })
            this.body.classList.add('is-high-contrast-active')
        } else {
            Cookies.remove('high-contrast-mode')
            this.body.classList.remove('is-high-contrast-active')
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const highContrasts = Array.from(document.querySelectorAll('.js-high-contrast'));
    if (highContrasts) {
        highContrasts.forEach(highContrast => {
            new HighContrast(highContrast);
        })
    }
});