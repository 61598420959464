class HeaderMobile{
    constructor() {
        this.el = document.querySelector('.js-header-mobile');
        this.burgerEl = this.el.querySelector('.js-header-mobile__hamburger');
        this.backdrop = this.el.querySelector('.js-header-mobile__backdrop');


        this.burgerEl.addEventListener('click', () => {
            this.toggle()
            this.toggleHamburger();
        });

        this.backdrop.addEventListener('click', () => {
            this.toggle()
            this.toggleHamburger();
        });
    }

    toggle() {
        this.el.classList.toggle('is-active');
    }

    toggleHamburger() {
        this.burgerEl.classList.toggle('is-active');
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new HeaderMobile();
});
