import Swiper, {Autoplay, EffectCreative} from 'swiper';

class HeroSlider {
    constructor(element) {
        this.element = element;
        const nextButton = element.querySelector('.js-hero-slider__next');
        const prevButton = element.querySelector('.js-hero-slider__prev');

        const slider = new Swiper(element.querySelector('.js-hero-slider__slider'), {
            modules: [Autoplay, EffectCreative],
            spaceBetween: 0,
            effect: 'creative',
            creativeEffect: {
                prev: {
                    // will set `translateZ(-400px)` on previous slides
                    translate: [0, 0, -400],
                },
                next: {
                    // will set `translateX(100%)` on next slides
                    translate: ['100%', 0, 0],
                },
            },
            autoplay: {
                delay: 8000,
            },
            on: {
                transitionEnd: (swiper) => {
                    this.toggleSliderButtons(swiper, prevButton, nextButton)
                    this.animateSlide(swiper)
                },
                afterInit: (swiper) => this.toggleSliderButtons(swiper, prevButton, nextButton),
                resize: (swiper) => this.toggleSliderButtons(swiper, prevButton, nextButton)
            }
        });

        this.animateSlide(slider)

        nextButton.addEventListener('click', () => slider.slideNext(300));
        prevButton.addEventListener('click', () => slider.slidePrev(300));
    }

    animateSlide(swiper) {
        const slide = swiper.slides[swiper.activeIndex]
        if (!slide.classList.contains('is-animating') && !slide.classList.contains('is-animated')) {
            slide.classList.add('is-animating')
            setTimeout(() => {
                slide.classList.add('is-animated-title')
            }, 2000)
            setTimeout(() => {
                slide.classList.add('is-animated-decor')
            }, 8000)
        }
    }

    toggleSliderButtons(swiper, prev, next) {
        if (swiper.isEnd) {
            next.classList.add('is-hidden');
        } else {
            next.classList.remove('is-hidden');
        }
        if (swiper.isBeginning) {
            prev.classList.add('is-hidden');
        } else {
            prev.classList.remove('is-hidden');
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const heroSliders = Array.from(document.querySelectorAll('.js-hero-slider'));
    if (heroSliders) {
        heroSliders.forEach(heroSlider => {
            new HeroSlider(heroSlider);
        })
    }
});