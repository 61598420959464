import Cookies from 'js-cookie'

class FontScale {
    constructor(element) {
        this.element = element;
        this.body = document.querySelector('body')
        this.mode = 0;

        const cookie = Cookies.get('font-scale-mode')

        if (cookie) {
            this.changeMode(parseInt(cookie))
        }

        this.element.addEventListener('click', (e) => {
            e.preventDefault()
            if (this.mode === 2) {
                this.changeMode(0)
            } else {
                this.changeMode(this.mode + 1)
            }
        })
    }

    changeMode(mode) {
        if (mode) {
            Cookies.set('font-scale-mode', mode, {expires: 30})
            this.body.classList.remove('font-scale-mode--' + this.mode)
            this.body.classList.add('font-scale-mode--' + mode)
        } else {
            Cookies.remove('font-scale-mode')
            this.body.classList.remove('font-scale-mode--2')
        }

        this.mode = mode
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const fontScales = Array.from(document.querySelectorAll('.js-font-scale'));
    if (fontScales) {
        fontScales.forEach(fontScale => {
            new FontScale(fontScale);
        })
    }
});