import datepicker from 'js-datepicker/dist/datepicker.min'
import axios from 'axios';
import qs from 'qs';

class EventsCalendar {
    constructor(element) {
        this.element = element;
        this.main = element.querySelector('.js-events-calendar__main')
        this.list = element.querySelector('.js-events-calendar__list')

        this.events = [];
        const events = JSON.parse(element.querySelector('.js-events-calendar__events').innerText)

        events.forEach(event => {
            this.events.push(new Date(event))

        })
        const currrentLang = document.documentElement.lang;

        if(currrentLang == 'pl-PL'){this.calendar = datepicker(this.main, {
            alwaysShow: true,
            startDay: 1,
            disableYearOverlay: true,
            customDays: ['Ndz.', 'Pon.', 'Wt.', 'Śr.', 'Czw.', 'Pt.', 'Sob.'],
            customMonths: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
            events: this.events,
            onSelect: (instance, date) => {
                this.updateList(date.getTime() - (date.getTimezoneOffset() * 60 * 1000))
            },
        })}
        else {
            this.calendar = datepicker(this.main, {
                alwaysShow: true,
                startDay: 1,
                disableYearOverlay: true,
                customDays: ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'],
                customMonths: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                events: this.events,
                onSelect: (instance, date) => {
                    this.updateList(date.getTime() - (date.getTimezoneOffset() * 60 * 1000))
                },
            })
        }


        this.list.parentElement.querySelector('.qs-square.qs-current').classList.add('qs-active')
    }

    updateList(time) {
        const data = {
            action: 'events_calendar_get_list_html',
            time: time
        }
        axios({
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/wp/wp-admin/admin-ajax.php',
            data: qs.stringify(data)
        }).then(response => {
            this.list.innerHTML = response.data.html
            window.lazyLoading.updateLazyLoading()
        }).catch(error => {
            console.log(error);
            console.log(error.response.data)
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const eventsCalendars = Array.from(document.querySelectorAll('.js-events-calendar'));
    if (eventsCalendars) {
        eventsCalendars.forEach(eventsCalendar => {
            new EventsCalendar(eventsCalendar);
        })
    }
});