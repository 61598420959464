class Video {
    constructor(element) {
        this.element = element;
        this.inner = this.element.querySelector('.js-video__inner')
        this.iframe = this.element.querySelector('iframe')

        this.inner.addEventListener('click', e => {
            if(!this.element.classList.contains('is-active')) {
                this.iframe.setAttribute('src', this.iframe.dataset.src)
                this.element.classList.add('is-active')
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const videos = Array.from(document.querySelectorAll('.js-video'));
    if (videos) {
        videos.forEach(video => {
            new Video(video);
        })
    }
});