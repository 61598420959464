import Swiper, {Autoplay} from 'swiper';

class DepartmentsSlider {
    constructor(element) {
        this.element = element;
        const prevButton = element.querySelector('.js-blocks-slider__prev');
        const nextButton = element.querySelector('.js-blocks-slider__next');

        const isSecondary = element.classList.contains('js-blocks-slider--secondary')
        let options = {
            modules: [Autoplay],
            loop: true,
            slidesPerView: 1,
            spaceBetween: 12,
            autoplay: {
                delay: 5000,
            },
            on: {
                transitionEnd: (swiper) => this.toggleSliderButtons(swiper, prevButton, nextButton),
                afterInit: (swiper) => this.toggleSliderButtons(swiper, prevButton, nextButton),
                resize: (swiper) => this.toggleSliderButtons(swiper, prevButton, nextButton)
            },
        };

        if (!isSecondary) {
            options = Object.assign(options, {
                breakpoints: {
                    576: {
                        slidesPerView: 2
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 24
                    },
                    1200: {
                        slidesPerView: 4
                    }
                },
            })
        } else {
            options = Object.assign(options, {
                breakpoints: {
                    576: {
                        slidesPerView: 2
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 24
                    },
                },
            })
        }

        const slider = new Swiper(element.querySelector('.js-blocks-slider__slider'), options);

        nextButton.addEventListener('click', () => slider.slideNext(300));
        prevButton.addEventListener('click', () => slider.slidePrev(300));
    }

    toggleSliderButtons(swiper, prev, next) {
        if (swiper.isEnd) {
            next.classList.add('is-hidden');
        } else {
            next.classList.remove('is-hidden');
        }
        if (swiper.isBeginning) {
            prev.classList.add('is-hidden');
        } else {
            prev.classList.remove('is-hidden');
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const departmentsSliders = Array.from(document.querySelectorAll('.js-blocks-slider'));
    if (departmentsSliders) {
        departmentsSliders.forEach(departmentsSlider => {
            new DepartmentsSlider(departmentsSlider);
        })
    }
});