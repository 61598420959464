import '../img/logo.svg';
import '../img/logo-mini.svg';
import '../img/triangle-arrow.svg';
import '../img/contrast.svg';
import '../img/youtube_logo.svg';
import '../img/magnifier.svg';

import '../img/icons/ico-facebook.svg';
import '../img/icons/ico-instagram.svg';
import '../img/icons/ico-twitter.svg';
import '../img/icons/ico-linkedin.svg';
import '../img/icons/ico-youtube.svg';
import '../img/icons/ico-mail.svg';
import '../img/icons/ico-flickr.svg';

import '../img/shapes/shape-bitten-rectangle.svg';
import '../img/shapes/shape-chessboard.svg';
import '../img/shapes/shape-circle.svg';
import '../img/shapes/shape-duplicate-with-hole.svg';
import '../img/shapes/shape-equals.svg';
import '../img/shapes/shape-fast-forward.svg';
import '../img/shapes/shape-fast-forward-up.svg';
import '../img/shapes/shape-fuji.svg';
import '../img/shapes/shape-message.svg';
import '../img/shapes/shape-plus.svg';
import '../img/shapes/shape-square-with-hole.svg';
import '../img/shapes/shape-stairs.svg';
import '../img/shapes/shape-triangle.svg';
import '../img/shapes/shape-triangles-to-middle.svg';

document.addEventListener('DOMContentLoaded', () => {
    document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
})

import './lazyloading';
import './scrollToSection';
import './fixText';
import './sticky';

import '../../../includes/views/components/atoms/high-contrast/high-contrast';
import '../../../includes/views/components/atoms/font-scale/font-scale';

import '../../../includes/views/components/molecules/full-size-gallery/full-size-gallery';
import '../../../includes/views/components/molecules/video/video';
import '../../../includes/views/components/molecules/search-bar/search-bar';

import '../../../includes/views/components/organisms/main-nav-mobile/main-nav-mobile';
import '../../../includes/views/components/organisms/gallery/gallery';
import '../../../includes/views/components/organisms/accordion/accordion';
import '../../../includes/views/components/organisms/hero-slider/hero-slider';
import '../../../includes/views/components/organisms/blocks-slider/blocks-slider';
import '../../../includes/views/components/organisms/departments-map/departments-map';
import '../../../includes/views/components/organisms/events-calendar/events-calendar';

import '../../../includes/views/layouts/header-mobile/header-mobile';
import '../../../includes/views/layouts/header/header';
import '../../../includes/views/layouts/sidebar/sidebar';