'use strict'
class Sidebar {
    constructor(el) {
        this.el = el
        this.hamburger = this.el.querySelector('.js-sidebar__hamburger')

        this.hamburger.addEventListener('click', e => {
            e.preventDefault()
            this.toggle()
        })
    }

    toggle() {
        this.hamburger.classList.toggle('is-active')
        this.el.classList.toggle('is-active')
    }
}

document.addEventListener('DOMContentLoaded', () => {
    let elements = document.querySelectorAll('.js-sidebar')
    elements.forEach((el) => {
        new Sidebar(el);
    })
    let menuItem = document.querySelectorAll('.menu__link')
    menuItem.forEach((iks) => {
        if (iks.href === window.location.href) {
            iks.classList.add('menu__link--current');
        }
    })
});
